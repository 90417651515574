import KeenSlider from "keen-slider";

const sliderElement = document.querySelector(".keen-slider");
const arrowLeft = document.querySelector(".slider-prev");
const arrowRight = document.querySelector(".slider-next");
const imagesWrapper = [...document.querySelectorAll("#timeline-images image")];
const historySvg = document.querySelector("#history-svg");

if (window.innerWidth < 768) {
  historySvg.setAttribute("viewBox", "346.44 0 747 562");
}

if (sliderElement) {
  const slider = new KeenSlider(sliderElement, {
    loop: true,
    created: () => {
      arrowLeft.addEventListener("click", () => slider.prev());
      arrowRight.addEventListener("click", () => slider.next());
    },
    defaultAnimation: {
      duration: 0,
    },
    detailsChanged: (s) => {
      s.slides.forEach((element, idx) => {
        element.style.opacity = s.track.details.slides[idx].portion;
        imagesWrapper[idx].style.opacity = s.track.details.slides[idx].portion;

        if (s.track.details.slides[idx].portion == 0) {
          element.style.pointerEvents = "none";
        } else {
          element.style.pointerEvents = "auto";
        }
      });
    },
    renderMode: "custom",
  });
}
