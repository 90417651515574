import {gsap} from "gsap";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

const scrollToTopTriggers = [
  ...document.querySelectorAll("[data-scroll-to-top]"),
];

scrollToTopTriggers.forEach((trigger) =>
  trigger.addEventListener("click", () => {
    gsap.to(window, {duration: 1, delay: 0, ease: "none", scrollTo: 0});
  })
);
