const modals = [...document.querySelectorAll("[data-modal-id]")];

if (modals.length) {
  const modalTriggers = [
    ...document.querySelectorAll("[data-modal-id-trigger]"),
  ];
  const modalCloseOverlay = document.querySelector(".modal__close");
  const modalCloseButtons = [
    ...document.querySelectorAll(".modal__close-button"),
  ];

  modalCloseButtons.forEach((modalCloseButton) => {
    modalCloseButton.addEventListener("click", () => {
      modals.forEach((modal) => {
        modal.classList.remove("modal__wrapper--active");
      });
      document.body.classList.remove("no-scroll");
    });
  });

  modalTriggers.forEach((modalTrigger) => {
    const triggerId = modalTrigger.getAttribute("data-modal-id-trigger");

    const modal = modals.filter((item) => {
      const modalId = item.getAttribute("data-modal-id");
      return modalId === triggerId;
    })[0];

    modalTrigger.addEventListener("click", () => {
      modal.classList.add("modal__wrapper--active");
      document.body.classList.add("no-scroll");
    });

    modalCloseOverlay.addEventListener("click", () => {
      modal.classList.remove("modal__wrapper--active");
      document.body.classList.remove("no-scroll");
    });

    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        modal.classList.remove("modal__wrapper--active");
        document.body.classList.remove("no-scroll");
      }
    });
  });
}
