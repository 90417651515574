import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const shapes = [...document.querySelectorAll(".clip-path-animation .svg")];

if (shapes && shapes.length) {
  const imageSection = document.querySelector(".hero__section--image");
  const content = imageSection.querySelector(".hero__text");
  const imageSectionImage = document.querySelector(".hero__section--image img");
  const imageWrapper = document.querySelector(
    ".hero__section--image .image-scale-wrapper"
  );
  const caption = document.querySelector(".frontpage-caption");
  const title = document.querySelector(".frontpage-title");

  gsap.to([caption, title], {
    y: 0,
    yPercent: 0,
    autoAlpha: 1,
    duration: 1,
    ease: "cubic-bezier(0.77, 0, 0.18, 1)",
    delay: 0.2,
  });

  gsap.set(shapes, {
    clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
  });

  gsap.set(content, {
    autoAlpha: 0,
    y: 32,
  });

  shapes.forEach((shape) => {
    gsap.to(shape, {
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      duration: 1,
      ease: "cubic-bezier(0.77, 0, 0.18, 1)",
      scrollTrigger: {
        trigger: shape,
        start: "top center",
        scrub: false,
      },
    });
  });

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: imageSection,
      start: "top center",
      end: "top top",
      scrub: true,
    },
  });

  tl.to(imageSectionImage, {
    scale: 1,
  }).to(
    [content, imageWrapper],
    {
      autoAlpha: 1,
      y: 0,
    },
    0
  );
}
