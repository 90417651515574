import { gsap } from "gsap";

const MENU = document.querySelector(".menu");
const MENU_TRIGGER = document.querySelector(".menu__trigger");
const MENU_OPEN = MENU_TRIGGER.querySelector(".menu__open");
const MENU_CLOSE = MENU_TRIGGER.querySelector(".menu__close");
const MENU_LINKS = [...document.querySelectorAll(".menu__link")];
const MENU_LANGUAGES = [...document.querySelectorAll("nav.menu__language")];

export default class Menu {
	constructor() {
    this.isAnimating = false;
		this._setInitialStates();

		this.timeline = gsap.timeline({
			paused: true,
      reversed: true,
			defaults: {
				duration: 0.4,
        ease: "cubic-easier(0.76, 0, 0.24, 1)",
			},
		});

		this.timeline
    .add(this._animateButton())
    .add(this._expandMenu(), '-=0.4')
    .add(this._revealMenuItems());

		MENU_TRIGGER.addEventListener("click", () => {
			this.timeline.reversed() ? this.timeline.play() : this.timeline.reverse();
		});
	}

	_setInitialStates() {
		gsap.set(MENU, { 
			scale: 0,
		});
	}

	_animateButton() {
		const tl = gsap.timeline();

		tl.to(MENU_OPEN, {
			xPercent: -100,
			opacity: 0,
			ease: 'power2.inOut'
				}).to(MENU_CLOSE, {
						xPercent: -103, 
			},0.2);

		return tl;
	}

	_expandMenu() {
		const tl = gsap.timeline();

		tl.to(MENU, {
			scale: 1,
			duration: 0.4,
		});

		return tl;
	}

	_revealMenuItems() {
		const tl = gsap.timeline();

		tl.to([MENU_LINKS], {
      yPercent: 0,
			y: 0,
			autoAlpha: 1,
			stagger: 0.05,
		})

		return tl;
	}
}

new Menu();
