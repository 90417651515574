import "@/styles/index.scss";
import "./utils/scroll";
import "./animation/Menu";
import "./animation/Modals";
import "./animation/Shape";
import "./animation/Timeline";
import "./animation/PersonCard";
import "./PageTransition/index";

class App {
  constructor() {
    this._addEventListeners();
    this._render();
  }

  _onMouseMove(event) {}

  _onResize() {}

  _addEventListeners() {
    window.addEventListener("resize", this._onResize.bind(this));
    window.addEventListener("mousemove", this._onMouseMove.bind(this));
  }

  _render(time) {
    window.requestAnimationFrame(this._render.bind(this));
  }
}

new App();

console.log(
  "%c Developed by ANTI - https://anti.as/",
  "background: #000; color: #fff;"
);
